import React from 'react'
import { connect } from 'react-redux'
import { Pagination } from 'react-bootstrap'
import PositionRow from './PositionRow'
import * as accountActions from '../../actions/account'

export const Positions = ({positions=[]}) => (
  <div className="card mt-3">

    <div className="card-header bg-info">
      <span style={{color:'white', fontSize:'14px', fontWeight:'bold'}}>POSITIONS</span>
    </div>

    <div className="card-body">

      <table className="table table-responsive" style={{fontSize:'12px'}}>
        <thead>
          <tr>
            <th>Symbol</th>
            <th>AvgPx</th>
            <th>Qty</th>
            <th>Quote</th>
            <th>Exposure</th>
          </tr>
        </thead>
        <tbody>
          { positions.map( (position, i) => (
            <PositionRow position={position} key={i} />
          ))}
        </tbody>
      </table>

    </div> {/* card-body */}
  </div>
)

export const mapStateToProps = (state) => ({
  positions: state.account.positions
})

export const mapDispatchToProps = (dispatch) => ({
  fetchPositions() {
    //dispatch( accountActions.fetchAccountPositions() )
  }
})

class PositionsC extends React.Component {
  componentDidMount() {
    /*
    const self = this
    this.timerId = setInterval(
      () => {
        self.props.fetchPositions()
      }, 5000
    )
    */
  }
  
  componentWillUnmount() {
    /*
    console.log('componentWillUnmount')
    clearInterval( this.timerId )
    */
  }
  
  render() {
    return <Positions {...this.props} />
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PositionsC)
