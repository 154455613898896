import React from 'react'
import { connect } from 'react-redux'
import { Pagination } from 'react-bootstrap'
import * as wlActions from '../../actions/watchlist'
import InstrumentRow from './InstrumentRow'

export const Watchlist = ({names=[], instruments=[]}) => (
  <div className="card mt-3">
  
    <div className="card-header bg-primary">
      {/*
      <div style={{marginTop:'0',float:'right'}}>
    		<i className="fa fa-plus"></i>&nbsp;&nbsp;
    		<i className="fa fa-pencil"></i>&nbsp;&nbsp;
    		<i className="fa fa-trash"></i>
      </div>
      */}
      <span style={{color:'white', fontWeight:'bold'}}>WATCHLISTS</span>
    </div>

    <div className="card-body">
      <form>
        <div className="form-group">
          <select className="form-control" placeholder="no watchlists">
            {
              names.map( (item, i) => (
                <option value={item.id} key={i}>{item.name}</option>
              ))
            }
          </select>
        </div>
      </form>
  
      <table className="table table-responsive" style={{fontSize:'12px'}}>
        <thead>
          <tr>
            <th>Symbol</th>
            <th>Bid</th>
            <th>Ask</th>
          </tr>
        </thead>
        <tbody>
          { instruments.map( (item, i) => (
            <InstrumentRow instrument={item} key={i} />
          ))}
        </tbody>
      </table>

      {/*
      <div className="row">
        <div className="col-sm-3">
          <button className="btn btn-sm btn-primary">
            <i className="fa fa-plus"></i>&nbsp;&nbsp;Symbol
          </button>
        </div>
        <div className="col-sm-9">
          <Pagination
            bsSize="small"
            style={{marginTop:'0', marginBottom:'0', float:'right'}}
            prev
            next
            first
            last
            boundaryLinks
            items={5}
            maxButtons={3}
            activePage={2}
          />
        </div>
      </div>
      */}
        
    </div> {/* card-body */}
  </div>
)

export const mapStateToProps = state => ({
  names: state.watchlist.names,
  currId: state.watchlist.currId,
  instruments: state.watchlist.instruments
})


export const mapDispatchToProps = (dispatch) => ({
  onMount() {
    dispatch( wlActions.panelMount() )
  }
})

class WatchlistC extends React.Component {
  componentDidMount() {
    this.props.onMount()
  }
  
  render() {
    return <Watchlist {...this.props} />
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WatchlistC)
