import React from 'react' 
import { connect } from 'react-redux'
import { usernameChange, passwordChange, login } from '../actions/login'

import {
  Row,
  Col,
  Grid,
  Form,
  Panel,
  Button,
  FormGroup,
  FormControl,
  Alert
} from 'react-bootstrap'

export class Login extends React.Component {
  render() {
    let {
      alertText,
      username,
      password,
      onUsernameChange,
      onPasswordChange,
      onSubmit
    } = this.props
    return (
      <Grid>
        <Row>
          <Col sm={4} smOffset={4} xs={10} xsOffset={1}>
            <div className="center-block" style={{paddingTop:'100px'}}>
              <Panel bsStyle="primary">
                <h3
                  className="text-center text-primary"
                  style={{marginTop:'10px', marginBottom:'20px'}}
                >Login to your account</h3>
                {
                  alertText && <Alert bsStyle="danger" className="text-center">{alertText}</Alert>
                }
                <form onSubmit={e => {
                  e.preventDefault()
                  onSubmit(
                    username,
                    password
                  )
                }}>
                  <FormGroup>
                    <FormControl
                      type="text"
                      value={username}
                      ref="username"
                      onChange={
                        e => { onUsernameChange(e.target.value) }
                      }
                      autoFocus
                      bsSize="large"
                      placeholder="Enter user name"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControl
                      type="password"
                      value={password}
                      ref="password"
                      onChange={
                        e => { onPasswordChange(e.target.value) }
                      }
                      bsSize="large"
                      placeholder="Enter password"
                    />
                  </FormGroup>
                  <Button type="submit" bsSize="large" bsStyle="primary" block>Login</Button>
                </form>
              </Panel>
            </div>
          </Col>
        </Row>
      </Grid>
    )
  }
}

export const mapStateToProps = (state) => ({
  alertText: state.login.form.alertText,
  username: state.login.form.username,
  password: state.login.form.password
})

export const mapDispatchToProps = (dispatch) => ({
  onUsernameChange(username) {
    dispatch( usernameChange(username) )
  },
  onPasswordChange(password) {
    dispatch( passwordChange(password) )
  },
  onSubmit(username, password) {
    dispatch( login(username, password) )
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login)

