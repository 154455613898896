import moment from 'moment'

const makeOrderString = (message) => (
  `${message.order.side} ${message.order.quantity} ` +
  `${message.order.instrument.symbol} ` +
  `at ${message.order.price}`
)

const etsRouteEvent = (message) => {
  let ret

  const date = moment(message.timestamp).format('YYYY-MM-DD HH:mm:ss')
  const state = message.order.state == 'SENDING'
    ? 'Sending' : message.order.state

  if(message.type != 'NEWORDER') {
    ret = `${date} - ${message.type} route event`
  } else {
    ret = `${date} - ${state} new order - ` +
      makeOrderString(message)
  }
  
  return ret
}

export const makeMsgString = (message) => {
  const date = moment(message.timestamp).format('YYYY-MM-DD HH:mm:ss')

  switch( message.eventtype) {
    
    case 'com.tradedesksoftware.ets.events.ETSRouteEvent':
      // don't display route events
      return null
    
    case 'com.tradedesksoftware.ets.events.ETSLogEvent':
      return `${date} - ${message.logEntry.text}`
      
    case 'com.tradedesksoftware.ets.events.ETSClientSubscription':
      return `${date} - waiting for messages`
      
    case 'com.tradedesksoftware.ets.events.ETSExecutionEvent':
      if(message.execution.finalState == 'OPEN') {
        return `${date} - order ${message.execution.cpOrderid} ` +
          `opened on server`
      } else if(message.execution.finalState == 'FILLED') {
        return `${date} - order ${message.execution.cpOrderid} ` +
          `filled ` + makeOrderString(message)
      }
      
    default:
      console.log(JSON.stringify(message))
      return `${date} - Unknown event type - ${message.eventtype}`
  }
}
