import {
  APP_INIT_FAILURE,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  RTR_MOUNT_COMP
} from '../actions/actionTypes'

const initialState = {
  mainCompName: 'Login'
}

const router = (state=initialState, action) => {
  if(!action) return state
  
  switch(action.type) {
    
    case APP_INIT_FAILURE:
      return Object.assign({}, state,
        {mainCompName: 'AppError'}
      )
      
    case LOGIN_SUCCESS:
      return Object.assign({}, state,
        {mainCompName: 'TraderDashboard'}
      )
      
    case LOGOUT_SUCCESS:
      return Object.assign({}, state,
        {mainCompName: 'Login'}
      )
    
    case RTR_MOUNT_COMP:
      return Object.assign({}, state,
        {mainCompName: action.payload}
      )
      
    default:
      return state
  }
}

export default router
