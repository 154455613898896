import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import storeFactory from './store'
import {initApp} from './actions/app'
import * as serviceWorker from './serviceWorker';

import './index.css';
import App from './components/App'

const store = storeFactory()
store.dispatch( initApp() )

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)

serviceWorker.unregister();
