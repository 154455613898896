'use strict'

import {
  APP_ERROR_MODAL_CLOSE_CLICK,
  APP_INIT_FAILURE,
  FETCH_USER_SUCCESS,
  RTR_MOUNT_COMP
} from './actionTypes'

import * as backend from '../backend/login'

export const errorModalCloseClick = () => ({
  type: APP_ERROR_MODAL_CLOSE_CLICK
})

export const initApp = () => (dispatch) => {
  dispatch({
    type: RTR_MOUNT_COMP,
    payload: 'Login'
  })
  /*
  return backend.getCurrentUser()
    .then(user => {
      console.log('initApp user', user)
      if(!user) {
      } else {
        dispatch({
          type: FETCH_USER_SUCCESS,
          payload: user
        })
        dispatch({
          type: RTR_MOUNT_COMP,
          payload: 'TraderDashboard'
        })
      }
    })
    .catch(err => {
      console.log('initApp error', err)
      dispatch({ type: APP_INIT_FAILURE })
    })
  */
}