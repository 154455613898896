import React from 'react'
import { connect } from 'react-redux'
import { ListGroup, ListGroupItem, Pagination } from 'react-bootstrap'
import * as accountActions from '../actions/account'

export const Messages = ({messages=[]}) => (
  <div className="card mt-3">

    <div className="card-header bg-info">
      <span style={{color:'white', fontSize:'14px', fontWeight:'bold'}}>MESSAGES</span>
    </div>

    <div className="card-body">

      <ListGroup style={{fontSize:'12px'}}>
        { messages.map( (m, i) => (
          <ListGroupItem key={i}>{m.text}</ListGroupItem>
        ))}
      </ListGroup>
      
    </div> {/* card-body */}
  </div>
)

export const mapStateToProps = (state) => ({
  messages: state.account.messages
})

export const mapDispatchToProps = (dispatch) => ({
  fetchMessages() {
    //dispatch( accountActions.fetchMessages() )
  },
  setMessageType() {
    //dispatch( accountActions.setMessageType() )
  }
})

class MessagesC extends React.Component {
  componentDidMount() {
    this.props.setMessageType()
    
    const self = this
    this.timerId = setInterval(
      () => {
        self.props.fetchMessages()
      }, 5000
    )
  }
  
  componentWillUnmount() {
    console.log('componentWillUnmount')
    clearInterval( this.timerId )
  }
  
  render() {
    return <Messages {...this.props} />
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MessagesC)
