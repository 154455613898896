export const serverUrl = 'http://localhost:8080/'
//export const serverUrl = 'http://deploy.marx.tech:8080/'
//export const serverUrl = 'http://35.162.230.13:8080/'

export const dataMgmtUrl = serverUrl + 'etsdatamanagement/';
export const loginUrl = serverUrl+'login?';
export const logoutUrl = serverUrl+'jsxmgr/LogOut';

export const dataMgmtRestUrl = serverUrl + 'etsdatamanagement/rest/';
export const marketDataRestUrl = serverUrl + 'etsmarketdata/rest/';
export const orderMgmtRestUrl = serverUrl + 'omsrestservices/rest/';

export const getWatchlistIdsUrl = dataMgmtRestUrl + 'watchlist/ids'

export const username = 'administrator'
export const password = 'marx'
