import {
  APP_ERROR_MODAL_CLOSE_CLICK,
  FETCH_USER_FAILURE,
  FETCH_ACCOUNT_ORDERS_FAILURE,
  FETCH_ACCOUNT_POSITIONS_FAILURE,
  FETCH_MESSAGES_FAILURE,
  FETCH_USER_ACCOUNTS_FAILURE,
  FETCH_WATCHLIST_FAILURE,
  FETCH_WATCHLIST_NAMES_FAILURE,
  SET_MESSAGE_TYPE_FAILURE,
  ORDER_FORM_MOUNT_FAILURE
} from '../actions/actionTypes'

const initialState = {
  modalShow: false,
  modalText: ''
}

const appError = (state=initialState, action) => {
  if(!action) return state
  
  switch(action.type) {

    case APP_ERROR_MODAL_CLOSE_CLICK:
      return {
        modalShow: false,
        modalText: state.modalText
      }

    case FETCH_ACCOUNT_ORDERS_FAILURE:
      return {
        modalShow: true,
        modalText: 'Could not fetch account orders'
      }

    case FETCH_ACCOUNT_POSITIONS_FAILURE:
      return {
        modalShow: true,
        modalText: 'Could not fetch account positions'
      }
  
    case FETCH_USER_FAILURE:
      return {
        modalShow: true,
        modalText: action.payload.msg
      }
  
    case FETCH_USER_ACCOUNTS_FAILURE:
      return {
        modalShow: true,
        modalText: 'Could not fetch user accounts'
      }
  
    case ORDER_FORM_MOUNT_FAILURE:
      return {
        modalShow: true,
        modalText: 'Could not fetch order panel data - ' +
          action.payload
      }

    case FETCH_WATCHLIST_NAMES_FAILURE:
      return {
        modalShow: true,
        modalText: 'Could not fetch watchlist names'
      }
  
    case FETCH_WATCHLIST_FAILURE:
      return {
        modalShow: true,
        modalText: 'Could not fetch watchlist'
      }
  
    case FETCH_MESSAGES_FAILURE:
      return {
        modalShow: true,
        modalText: 'Could not fetch order messages'
      }
  
    case SET_MESSAGE_TYPE_FAILURE:
      return {
        modalShow: true,
        modalText: 'Could not set order message type'
      }
      
    default:
      return state
      
  }
}

export default appError
