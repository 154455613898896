import { TRADER_DASHBOARD_PATH } from '../constants'

import {
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  LOGIN_USERNAME_CHANGE,
  LOGIN_PASSWORD_CHANGE, 
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_SUCCESS
} from './actionTypes'

import * as backend from '../backend/login'

export const usernameChange = (newUsername) => ({
  type: LOGIN_USERNAME_CHANGE,
  payload: newUsername
})

export const passwordChange = (newPassword) => ({
  type: LOGIN_PASSWORD_CHANGE,
  payload: newPassword
})

export const loginFailure = () => ({
  type: LOGIN_FAILURE
})

export const fetchUserSuccess = (user) => ({
  type: FETCH_USER_SUCCESS,
  payload: user
})

export const fetchUserFailure = (msg) => ({
  type: FETCH_USER_FAILURE,
  payload: msg
})

export const loginSuccess = () => ({
  type: LOGIN_SUCCESS
})

export const login = (username, password) => (dispatch) => {
  console.log('login action')
  if(username == 'admin' && password == 'chris') {
    dispatch( fetchUserSuccess({}) )
    dispatch( loginSuccess() )
  } else {
    dispatch( loginFailure() )
  }
  return Promise.resolve()
  /*
  return backend.login(username, password)
    .then(success => {
      if(success) {
        backend.getCurrentUser()
          .then(user => {
            console.log('user', user)
            if(user) {
              dispatch( fetchUserSuccess(user) )
              dispatch( loginSuccess() )
            } else {
              dispatch( fetchUserFailure('Could not fetch user data') )
            }
          })
      } else {
        dispatch( loginFailure() )
      }
    })
    .catch(err => {
      if(err) console.log(err)
      dispatch( loginFailure() )
    })
  */
}

export const logout = () => (dispatch) => {
  return backend.logout()
    .then(res => {
      dispatch({ type: LOGOUT_SUCCESS })
    })
}
