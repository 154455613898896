import uuidv4 from 'uuid/v4'

import {
  FETCH_MARKET_SYMBOLS_SUCCESS,
  FETCH_ORDER_TIFS_SUCCESS,
  FETCH_ORDER_TYPES_SUCCESS,
  FETCH_USER_DESTS_SUCCESS,
  ORDER_FORM_MOUNT_FAILURE,
  ORDER_FORM_HIDE,
  ORDER_FORM_SHOW,
  ORDER_FORM_INSTRUMENT_CHANGE,
  ORDER_FORM_TYPE_CHANGE,
  ORDER_FORM_PRICE_CHANGE,
  ORDER_FORM_QUANTITY_CHANGE,
  ORDER_FORM_TIF_CHANGE,
  ORDER_FORM_DEST_CHANGE
} from '../actions/actionTypes'

import * as orderBackend from '../backend/order'

export const instrumentChange = (index) => ({
  type: ORDER_FORM_INSTRUMENT_CHANGE,
  payload: index
})

export const typeChange = (type) => ({
  type: ORDER_FORM_TYPE_CHANGE,
  payload: type
})

export const priceChange = (price) => ({
  type: ORDER_FORM_PRICE_CHANGE,
  payload: price
})

export const quantityChange = (quantity) => ({
  type: ORDER_FORM_QUANTITY_CHANGE,
  payload: quantity
})

export const tifChange = (tif) => ({
  type: ORDER_FORM_TIF_CHANGE,
  payload: tif
})

export const destinationChange = (destinationId) => ({
  type: ORDER_FORM_DEST_CHANGE,
  payload: destinationId
})

export const fetchMarketSymbolsSuccess = (symbols) => ({
  type: FETCH_MARKET_SYMBOLS_SUCCESS,
  payload: symbols
})

export const fetchOrderTifsSuccess = (tifs, permittedIndices) => ({
  type: FETCH_ORDER_TIFS_SUCCESS,
  payload: { tifs, permittedIndices }
})

export const fetchOrderTypesSuccess = (types, permittedIndices) => ({
  type: FETCH_ORDER_TYPES_SUCCESS,
  payload: { types, permittedIndices }
})

export const fetchUserDestsSuccess = (dests) => ({
  type: FETCH_USER_DESTS_SUCCESS,
  payload: dests
})

export const mountFailure = (msg) => ({
  type: ORDER_FORM_MOUNT_FAILURE,
  payload: msg
})

export const hide = () => ({
  type: ORDER_FORM_HIDE
})

export const show = () => ({
  type: ORDER_FORM_SHOW
})

export const mount = () => (dispatch) => {
  return orderBackend.getUserDests()
    .then(dests => {
      if(!dests.length) {
        return dispatch( mountFailure('No destinations found') )
      }
      dispatch( fetchUserDestsSuccess(dests) )
      return orderBackend.getUserDestMarkets(dests[0].id)
        .then(markets => {
          if(!markets.length) {
            return dispatch( mountFailure('No markets found for destination') )
          }
          return orderBackend.getMarketSymbols(markets[0].marketid)
            .then(symbols => {
              dispatch( fetchMarketSymbolsSuccess(symbols) )
              return orderBackend.getAllOrderTypes()
                .then(types => {
                  dispatch( fetchOrderTypesSuccess(
                    types, markets[0].ordertypes
                  ))
                  return orderBackend.getAllOrderTifs()
                    .then(tifs => {
                      dispatch( fetchOrderTifsSuccess(
                        tifs, markets[0].tifs
                      ))
                    })
                })
            })
        })
    })
    .catch(err => {
      if(err) console.log(err)
      dispatch( mountFailure(err) )
    })
}

export const placeOrder = (side) => (dispatch, getState) => {
  const order = createOrderObj(side, getState)
  console.log(JSON.stringify(order))
  return orderBackend.place(order)
    .then(res => {
      dispatch( hide() )
    })
}

export const createOrderObj = (side, getState) => {
  const creationTime = Date.now()
  const custOrderId = uuidv4()
  const state = getState()
  const price = (state.orderForm.type == 'LIMIT')
    ? state.orderForm.price
    : null
  const instrument = state.orderForm.instruments[
    state.orderForm.instrumentIndex
  ]
  instrument.attributes = ["java.util.TreeMap",{}]

  return (
    {
      id: null,
      state: null,
      type: state.orderForm.type,
      offset: null,
      side: side.toUpperCase(),
      notes: null,
      companyId: state.login.user.companyId,
      ownerId: state.login.user.id,
      creationTime,
      quantity: parseInt(state.orderForm.quantity),
      instrument,
      relatedOrderId: 0,
      accountId: state.account.currId,
      destinationId: state.orderForm.destinationId,
      expireTime: 0,
      market: instrument.exchangeid,
      orderId: null,
      price,
      ticketId: null,
      tif: state.orderForm.tif,
      dispQty: 0,
      stopPrice: null,
      lastState: null,
      capacity: 'AGENCY',
      cpOrderId: null,
      avgprice: null,
      cumqty: 0,
      leavesqty: 0,
      aon: false,
      custOrderId,
      execInst: null,
      relatedClOrdId: null,
      quoteId: null
    }
  )
}