import React from 'react'

const PositionRow = ({position}) => (
  <tr>
    <td>{position.instrument.symbol}</td>
    <td>{Math.round(position.price*100)/100}</td>
    <td>{Math.round(position.quantity/1000)/100}</td>
    <td>0.00</td>
    <td>{Math.round( (position.quantity * position.price) * 100 )/100}</td>
  </tr>
)

export default PositionRow
