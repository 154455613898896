import { combineReducers } from 'redux'
import account from './account'
import appError from './appError'
import login from './login'
import orderForm from './orderForm'
import router from './router'
import watchlist from './watchlist'

const appReducer = combineReducers({
  account,
  appError,
  login,
  orderForm,
  router,
  watchlist
})

export default appReducer
