import React from 'react'
import OrderRow from './OrderRow'

const OrderTable = ({orders=[]}) => {
  return (
    <table className="table table-responsive" style={{fontSize:'12px'}}>
      <thead>
        <tr>
          <th>Created</th>
          <th>Instrument</th>
          <th>Fill</th>
          <th>Limit</th>
          <th>Qty</th>
          <th>Side/Type</th>
          <th>State</th>
        </tr>
      </thead>
      <tbody>
        { orders.map( (order, i) => (
          <OrderRow order={order} key={i} />
        ))}
      </tbody>
    </table>
  )
}

export default OrderTable
