'use strict';

const request = require('superagent');
const config = require('./config');

export const getWatchlistNames = () => {
  return new Promise( (resolve, reject) => {
    request.get(config.dataMgmtRestUrl+'watchlist/ids')
      .timeout(5000)
      .withCredentials()
      .set('Accept', '*')
      .then( res => {
        // return array of objects instead of
        // array of pipe delimited strings
        const names = res.body.map(item => (
          {
            id: item.split('|')[0],
            name: item.split('|')[1]
          }
        ))
        resolve(names)
      })
      .catch(err => {
        console.log('getWatchlistNames error', err)
        reject()
      })
  })
}

export const getWatchlist = (id) => {
  return new Promise( (resolve, reject) => {
    request.get(config.dataMgmtRestUrl+'watchlist/'+id)
      .timeout(5000)
      .withCredentials()
      .set('Accept', '*')
      .then( res => {
        const instruments = res.body.map.map(item => (
          {
            instrumentId: item.instrumentID,
            symbol: item.symbol,
            route: JSON.parse(item.routeString)
          }
        ))
        const ret = {
          id: res.body.id,
          name: res.body.name,
          instruments
        }
        resolve(ret)
      })
      .catch(err => {
        console.log('getWatchlist error', err)
        reject()
      })
  })
}
