import React from 'react'
import { connect } from 'react-redux'
import { Pagination, Tabs, Tab } from 'react-bootstrap'
import OrderTable from './OrderTable'
import * as accountActions from '../../actions/account'

export const Blotter = ({
  accounts=[],
  openOrders=[],
  rejectedOrders=[]
}) => (
  <div className="card mt-3">

    <div className="card-header bg-primary">
      <span style={{color:'white', fontSize:'14px', fontWeight:'bold'}}>BLOTTER</span>
    </div>

    <div className="card-body">
    
      <form>
        <div className="form-group">
          <select className="form-control">
            { accounts.map( (account, i) => (
              <option value={account.id} key={i}>{account.name}</option>
            ))}
          </select>
        </div>
      </form>

      <Tabs defaultActiveKey={1} id="order-tabs">
        <Tab eventKey={1} title="Open">
          <OrderTable orders={openOrders} />
        </Tab>
        <Tab eventKey={2} title="Exceptions">
          <OrderTable orders={rejectedOrders} />
        </Tab>
      </Tabs>
    </div> {/* panel-body */}
  </div>
)

export const mapStateToProps = (state) => ({
  accounts: state.account.accounts,
  currId: state.account.currId,
  openOrders: state.account.openOrders,
  rejectedOrders: state.account.rejectedOrders
})

export const mapDispatchToProps = (dispatch) => ({
  onMount() {
    //dispatch( accountActions.blotterPanelMount() )
  },
  fetchOrders() {
    dispatch( accountActions.fetchAccountOrders() )
  }
})

class BlotterC extends React.Component {
  componentDidMount() {
    /*
    this.props.onMount()

    const self = this
    this.timerId = setInterval(
      () => {
        self.props.fetchOrders()
      }, 5000
    )
    */
  }
  
  componentWillUnmount() {
    /*
    console.log('componentWillUnmount')
    clearInterval( this.timerId )
    */
  }
  
  render() {
    return <Blotter {...this.props} />
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BlotterC)
