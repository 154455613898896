import React from 'react'

const InstrumentRow = ({instrument}) => (
  <tr>
    <td>{instrument.symbol}</td>
    <td>{instrument.bid}</td>
    <td>{instrument.ask}</td>
  </tr>
)

export default InstrumentRow
