import {
  FETCH_USER_SUCCESS,
  LOGIN_USERNAME_CHANGE,
  LOGIN_PASSWORD_CHANGE, 
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS
} from '../actions/actionTypes'

const initialState = {
  user: null,
  form: {
    alertText: '',
    username: '',
    password: ''
  }
}

const login = (state=initialState, action) => {
  if(!action) return state
  
  switch(action.type) {

    case FETCH_USER_SUCCESS:
      return Object.assign({}, state,
        {user: action.payload} 
      )

    case LOGIN_FAILURE:
      return Object.assign({}, state,
        {form: 
          Object.assign({}, state.form,
            {alertText: 'Login failure'}
          )
        }
      )
    
    case LOGIN_USERNAME_CHANGE:
      return Object.assign({}, state,
        {form: 
          Object.assign({}, state.form,
            {username: action.payload}
          )
        }
      )
    
    case LOGIN_PASSWORD_CHANGE:
      return Object.assign({}, state,
        {form: 
          Object.assign({}, state.form,
            {password: action.payload}
          )
        }
      )

    case LOGIN_SUCCESS:
      return Object.assign({}, state, {
        form: {
          alertText: '',
          username: '',
          password: ''
        }
      })

    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {user: null})
      
    default:
      return state
  }
}

export default login 
