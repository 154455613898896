import React from 'react'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'
import * as appActions from '../actions/app'

export const AppErrorModal = ({show, text, onHide}) => (
  <Modal show={show} onHide={onHide}>
    <Modal.Header closeButton>
      <Modal.Title>Application Error</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <h3 style={{textAlign:'center'}}>{text}</h3>
    </Modal.Body>
  </Modal>
)

export const mapStateToProps = (state) => ({
  show: state.appError.modalShow,
  text: state.appError.modalText
})

export const mapDispatchToProps = (dispatch) => ({
  onHide() {
    dispatch( appActions.errorModalCloseClick() )
  }
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppErrorModal)
