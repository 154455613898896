import React from 'react'
import {connect} from 'react-redux'

import * as loginActions from '../actions/login'
import * as orderFormActions from '../actions/orderForm'

import TopNav from './TopNav'
import Watchlist from './Watchlist/Watchlist'
import Positions from './Positions/'
import Messages from './Messages'
import Blotter from './Blotter/'
import OrderModal from './OrderModal'

export class TraderDashboard extends React.Component {
  render() {
    let {
      onOrderBtnClick,
      onLogoutBtnClick
    } = this.props

    return (
      <div className="container-fluid">
        <TopNav
          onOrderBtnClick={onOrderBtnClick}
          onLogoutBtnClick={onLogoutBtnClick}
        />
        
        {/* main panels row */}
        <div className="row">
          
          {/* watchlist column */}
          <div className="col-sm-4">
            <Watchlist />
          </div>
          
          
          {/* right column */}
          <div className="col-sm-8">
          
            {/* blotter row */}
            <div className="row">
              <div className="col-sm-12"><Blotter /></div>
            </div>
          
            {/* positions and messages row */}
            <div className="row">
              <div className="col-sm-6"><Positions /></div>
              <div className="col-sm-6"><Messages /></div>
            </div>
            
            
          </div> {/* end right column */}
          
        </div> {/* end panels main row */}
    
        {/* <OrderModal /> */}
      </div>
    )
  }
}

export const mapDispatchToProps = (dispatch) => ({
  onOrderBtnClick() {
    dispatch( orderFormActions.show() )
  },
  onLogoutBtnClick() {
    dispatch( loginActions.logout() )
  }
})

export default connect(null, mapDispatchToProps)(TraderDashboard)
