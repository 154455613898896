import {
  FETCH_USER_ACCOUNTS_FAILURE,
  FETCH_USER_ACCOUNTS_SUCCESS,
  FETCH_ACCOUNT_ORDERS_FAILURE,
  FETCH_ACCOUNT_ORDERS_SUCCESS,
  FETCH_ACCOUNT_POSITIONS_FAILURE,
  FETCH_ACCOUNT_POSITIONS_SUCCESS,
  FETCH_MESSAGES_FAILURE,
  FETCH_MESSAGES_SUCCESS,
  SET_MESSAGE_TYPE_FAILURE,
  SELECT_ACCOUNT
} from './actionTypes'

import * as accountBackend from '../backend/account'
import * as orderBackend from '../backend/order'

export const setMessageTypeFailure = () => ({
  type: SET_MESSAGE_TYPE_FAILURE
})

export const fetchMessagesFailure = () => ({
  type: FETCH_MESSAGES_FAILURE
})

export const fetchMessagesSuccess = (msgs) => ({
  type: FETCH_MESSAGES_SUCCESS,
  payload: msgs
})

export const fetchUserAccountsFailure = () => ({
  type: FETCH_USER_ACCOUNTS_FAILURE
})

export const fetchUserAccountsSuccess = (accounts) => ({
  type: FETCH_USER_ACCOUNTS_SUCCESS,
  payload: accounts
})

export const fetchAccountOrdersFailure = () => ({
  type: FETCH_ACCOUNT_ORDERS_FAILURE
})

export const fetchAccountOrdersSuccess = (orders) => ({
  type: FETCH_ACCOUNT_ORDERS_SUCCESS,
  payload: orders
})

export const fetchAccountPositionsFailure = () => ({
  type: FETCH_ACCOUNT_POSITIONS_FAILURE
})

export const fetchAccountPositionsSuccess = (positions) => ({
  type: FETCH_ACCOUNT_POSITIONS_SUCCESS,
  payload: positions
})

export const selectAccount = (accountId) => ({
  type: SELECT_ACCOUNT,
  payload: accountId
})

export const blotterPanelMount = () => (dispatch, getState) => {
  return accountBackend.getForCurrentUser()
    .then(accounts => {
      dispatch( fetchUserAccountsSuccess(accounts) )

      if(accounts.length) {
        const userId = getState().login.user.id
        const firstAccountId = accounts[0].id
        dispatch( selectAccount(firstAccountId))

        return orderBackend.getForAccount(userId, firstAccountId)
          .then(orders => {
            dispatch( fetchAccountOrdersSuccess(orders) )
            accountBackend.getPositions(firstAccountId)
              .then(positions => {
                dispatch( fetchAccountPositionsSuccess(positions) )
              })
          })
          .catch(err => {
            if(err) console.log('getForAccount', err)
            dispatch( fetchAccountOrdersFailure() )
          })
      } // end if accounts.length
    })
    .catch(err => {
      dispatch( fetchUserAccountsFailure() )
    })
}

export const fetchAccountOrders = () => (dispatch, getState) => {
  //console.log('fetchAccountOrders')
  const state = getState()
  const userId = state.login.user.id
  const currAccountId = state.account.currId

  if(!currAccountId) return Promise.resolve()
  
  return orderBackend.getForAccount(userId, currAccountId)
    .then(orders => {
      //console.log('orders', orders)
      dispatch( fetchAccountOrdersSuccess(orders) )
    })
    .catch(err => {
      if(err) console.log('getForAccount', err)
      dispatch( fetchAccountOrdersFailure() )
    })
}

export const fetchAccountPositions = () => (dispatch, getState) => {
  const currAccountId = getState().account.currId
  return accountBackend.getPositions(currAccountId)
    .then(positions => {
      dispatch( fetchAccountPositionsSuccess(positions) )
    })
    .catch(err => {
      dispatch( fetchAccountPositionsFailure() )
    })
}

export const fetchMessages = () => (dispatch) => {
  return accountBackend.getMessages()
    .then(res => {
      if(res && res.emsgs) {
        if(res.emsgs.length) console.log(JSON.stringify(res.emsgs))
        dispatch( fetchMessagesSuccess(res.emsgs) )
      }
    })
    .catch(err => {
      if(err) console.log(err)
      dispatch( fetchMessagesFailure() )
    })
}

export const setMessageType = () => (dispatch) => {
  return accountBackend.setMessageType()
    .catch(err => {
      dispatch( setMessageTypeFailure() )
    })
}
