'use strict';

const request = require('superagent');
const config = require('./config');

export const getForCurrentUser = () => {
  return new Promise( (resolve, reject) => {
    request.get(config.orderMgmtRestUrl+'accounts')
      .timeout(5000)
      .withCredentials()
      .set('Accept', '*')
      .then( res => { resolve(res.body) })
      .catch(err => {
        console.log('getForCurrentUser error', err)
        reject()
      })
  })
}

export const getPositions = (accountId) => {
  return new Promise( (resolve, reject) => {
    request.get(config.orderMgmtRestUrl+`accounts/${accountId}/positions`)
      .timeout(5000)
      .withCredentials()
      .set('Accept', '*')
      .then( res => { resolve(res.body) })
      .catch(err => {
        console.log('getPositions error', err)
        reject()
      })
  })
}

export const setMessageType = (type) => {
  return new Promise( (resolve, reject) => {
    request.get(config.orderMgmtRestUrl+'orders/eventsource/USER')
      .timeout(5000)
      .withCredentials()
      .set('Accept', '*')
      .then( res => {
        if(res.status != 204) {
          reject()
        } else {
          resolve()
        }
      })
      .catch(err => {
        console.log('setMessageType', err)
        reject()
      })
  })
}

export const getMessages = () => {
  return new Promise( (resolve, reject) => {
    request.get(config.orderMgmtRestUrl+'orders/events')
      .timeout(5000)
      .withCredentials()
      .set('Accept', '*')
      .then( res => { resolve(res.body) })
      .catch(err => {
        console.log('getMessages', err)
        reject()
      })
  })
}
