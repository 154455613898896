'use strict';

const request = require('superagent');
const config = require('./config');

export const getForAccount = (userId, accountId) => {
  return new Promise( (resolve, reject) => {
    request.post(config.orderMgmtRestUrl+'findOrders')
      .timeout(5000)
      .withCredentials()
      .set('Accept', '*')
      .set('Content-Type', 'application/json')
      .send({
        userid: userId,
        accountid: accountId,
        showCalcs: true,
        quantity: 8,
        descending: true
      })
      .then( res => { resolve(res.body) })
      .catch(err => {
        console.log('getForCurrentUser error', err)
        reject()
      })
  })
}

export const place = (order) => {
  return new Promise( (resolve, reject) => {
    request.post(config.orderMgmtRestUrl+'orders/place')
      .timeout(5000)
      .withCredentials()
      .set('Accept', '*')
      .set('Content-Type', 'application/json')
      .send(order)
      .then( res => { console.log('place order res', res.body); resolve(res.body) })
      .catch(err => {
        console.log('place error', err)
        reject()
      })
  })
}

export const getUserDests = () => {
  return new Promise( (resolve, reject) => {
    request.get(config.orderMgmtRestUrl+'userDestinations')
      .timeout(5000)
      .withCredentials()
      .set('Accept', '*')
      .then( res => { resolve(res.body) })
      .catch(err => {
        console.log('getUserDests error', err)
        reject()
      })
  })
}

export const getUserDestMarkets = (destId) => {
  return new Promise( (resolve, reject) => {
    request.get(config.orderMgmtRestUrl+'destinations/info/user')
      .timeout(5000)
      .withCredentials()
      .set('Accept', '*')
      .then( res => {
        const destMarkets = res.body.filter(item => (
          item.destinationid == destId
        ))
        if(destMarkets.length) {
          resolve(destMarkets[0].markets) 
        } else {
          resolve([])
        }
      })
      .catch(err => {
        console.log('getUserDestMarkets error', err)
        reject()
      })
  })
}

export const getMarketSymbols = (marketId) => {
  return new Promise( (resolve, reject) => {
    request.get(config.marketDataRestUrl+'symbols/exchange/'+marketId)
      .timeout(5000)
      .withCredentials()
      .set('Accept', '*')
      .then( res => { resolve(res.body) })
      .catch(err => {
        console.log('getMarketSymbols error', err)
        reject()
      })
  })
}

export const getAllOrderTypes = () => {
  return new Promise( (resolve, reject) => {
    request.get(config.orderMgmtRestUrl+'meta/orders/types')
      .timeout(5000)
      .withCredentials()
      .set('Accept', '*')
      .then( res => { resolve(res.body) })
      .catch(err => {
        console.log('getAllOrderTypes error', err)
        reject()
      })
  })
}

export const getAllOrderTifs = () => {
  return new Promise( (resolve, reject) => {
    request.get(config.orderMgmtRestUrl+'meta/orders/tifs')
      .timeout(5000)
      .withCredentials()
      .set('Accept', '*')
      .then( res => { resolve(res.body) })
      .catch(err => {
        console.log('getAllOrderTifs error', err)
        reject()
      })
  })
}
