import React from 'react'
import {connect} from 'react-redux'
import AppError from './AppError'
import AppErrorModal from './AppErrorModal'
import Login from './Login'
import Splash from './Splash'
import TraderDashboard from './TraderDashboard'

export const App = ({mainCompName}) => {
  switch(mainCompName) {
    case 'AppError':
      return <AppError />
    case 'Login':
      return <Login />
    case 'TraderDashboard':
      return (
        <div>
          <TraderDashboard />
          {/* <AppErrorModal /> */}
        </div>
      )
    default:
      return <Splash />
  }
}

export const mapStateToProps = (state) => ({
  mainCompName: state.router.mainCompName
})

export default connect(mapStateToProps)(App)
