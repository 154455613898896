import React from 'react'
import { connect } from 'react-redux'

import { Button, Modal } from 'react-bootstrap'
import * as orderFormActions from '../actions/orderForm'

export class OrderModal extends React.Component {
  render() {
    let {
      show,
      alertText,
      instruments = [],
      instrumentIndex,
      types = [],
      type,
      tifs = [],
      tif,
      destinations = [],
      destinationId,
      onCancelClick,
      onSellClick,
      onBuyClick,
      onInstrumentChange,
      onTypeChange,
      onPriceChange,
      onQuantityChange,
      onTifChange,
      onDestinationChange
    } = this.props
    
    return (
      <Modal show={show}>
        <Modal.Header>
          <Modal.Title>Place Order</Modal.Title>
        </Modal.Header>
    
        <Modal.Body>
          { alertText
            ?
              <div className="alert alert-warning text-center">
                <h4 style={{marginBottom:'0'}}>{alertText}</h4>
              </div>
            : null
          }
          <form className="form-horizontal">
            <div className="form-group">
              <label htmlFor="instrumentDD" className="col-sm-3 control-label">Instrument</label>
              <div className="col-sm-9">
                <select
                  id="instrumentDD"
                  ref="instrumentDD"
                  value={instrumentIndex}
                  className="form-control"
                  onChange={e => { onInstrumentChange(e.target.value) }}
                >
                  { instruments.map( (ins, i) => (
                    <option value={i} key={i}>{ins.symbol}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="typeDD" className="col-sm-3 control-label">Order Type</label>
              <div className="col-sm-9">
                <select
                  id="typeDD"
                  ref="typeDD"
                  value={type}
                  className="form-control"
                  onChange={e => { onTypeChange(e.target.value) }}
                >
                  { types.map( (t, i) => (
                    <option value={t} key={i}>{t}</option>
                  ))}
                </select>
              </div>
            </div>
            { (type != 'LIMIT')
              ? null
              : <div className="form-group">
                  <label htmlFor="priceTxt" className="col-sm-3 control-label">Price</label>
                  <div className="col-sm-9">
                    <input
                      id="priceTxt"
                      type="text"
                      className="form-control"
                      required
                      onChange={e => { onPriceChange(e.target.value) }}
                    />
                  </div>
                </div>
            }
            <div className="form-group">
              <label htmlFor="quantityTxt" className="col-sm-3 control-label">Quantity</label>
              <div className="col-sm-9">
                <input
                  id="quantityTxt"
                  type="text"
                  className="form-control"
                  required
                  onChange={e => { onQuantityChange(e.target.value) }}
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="tifDD" className="col-sm-3 control-label">Expires</label>
              <div className="col-sm-9">
                <select
                  id="tifDD"
                  ref="tifDD"
                  value={tif}
                  className="form-control"
                  onChange={e => { onTifChange(e.target.value) }}
                >
                  { tifs.map( (tif, i) => (
                    <option value={tif} key={i}>{tif}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="destinationDD" className="col-sm-3 control-label">Destination</label>
              <div className="col-sm-9">
                <select
                  id="destinationDD"
                  ref="destinationDD"
                  value={destinationId}
                  className="form-control"
                  onChange={e => { onDestinationChange(e.target.value) }}
                >
                  { destinations.map( (d, i) => (
                    <option value={d.id} key={i}>{d.name}</option>
                  ))}
                </select>
              </div>
            </div>
          </form>
        </Modal.Body>
    
        <Modal.Footer>
          <button
            id="cancelBtn"
            className="btn btn-default"
            style={{width:'100px'}}
            onClick={onCancelClick}
          >Cancel</button>&nbsp;&nbsp;
          <button
            id="sellBtn"
            className="btn btn-danger"
            style={{width:'100px'}}
            onClick={onSellClick}
          >SELL</button>&nbsp;
          <button
            id="buyBtn"
            className="btn btn-success"
            style={{width:'100px'}}
            onClick={onBuyClick}
          >BUY</button>
        </Modal.Footer>
    
      </Modal>
    )
  }
}

export const mapStateToProps = (state) => (state.orderForm)

export const mapDispatchToProps = (dispatch) => ({
  onCancelClick() {
    dispatch( orderFormActions.hide() )
  },
  onSellClick() {
    dispatch( orderFormActions.placeOrder('sell') )
  },
  onBuyClick() {
    dispatch( orderFormActions.placeOrder('buy') )
  },
  onMount() {
    //dispatch( orderFormActions.mount() )
  },
  onInstrumentChange(instrumentId) {
    dispatch( orderFormActions.instrumentChange(instrumentId) )
  },
  onTypeChange(type) {
    dispatch( orderFormActions.typeChange(type) )
  },
  onQuantityChange(quantity) {
    dispatch( orderFormActions.quantityChange(quantity) )
  },
  onPriceChange(price) {
    dispatch( orderFormActions.priceChange(price) )
  },
  onTifChange(tif) {
    dispatch( orderFormActions.tifChange(tif) )
  },
  onDestinationChange(destinationId) {
    dispatch( orderFormActions.destinationChange(destinationId) )
  }
})

class OrderModalC extends React.Component {
  componentDidMount() {
    this.props.onMount()
  }
  
  render() {
    return <OrderModal {...this.props} />
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderModalC)
