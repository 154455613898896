import {
  FETCH_USER_ACCOUNTS_SUCCESS,
  FETCH_ACCOUNT_ORDERS_SUCCESS,
  FETCH_ACCOUNT_POSITIONS_SUCCESS,
  FETCH_MESSAGES_SUCCESS,
  SELECT_ACCOUNT
} from '../actions/actionTypes'

import { makeMsgString } from './helpers/'

const initialState = {
  accounts: [],
  currId: null,
  messages: [ { timestamp: null, text: 'Waiting for messages' } ],
  orders: [],
  positions: []
}

const account = (state=initialState, action) => {
  let messages

  if(!action) return state
  
  switch(action.type) {
    
    case FETCH_USER_ACCOUNTS_SUCCESS:
      return Object.assign({}, state, { accounts: action.payload })
    
    case SELECT_ACCOUNT:
      return Object.assign({}, state, { currId: action.payload })
    
    case FETCH_ACCOUNT_ORDERS_SUCCESS:
      // sort descending by creationTime
      const sortedOrders = action.payload.sort(
        (a, b) => {
          if(a.creationTime < b.creationTime) {
            return 1
          } else if(a.creationTime > b.creationTime) {
            return -1
          } else {
            return 0
          }
        }
      )
      
      const openOrders = sortedOrders.filter(item => (
        item.state == 'OPEN'
      ))
      
      const rejectedOrders = sortedOrders.filter(item => (
        item.state == 'REJECTED'
      ))

      return Object.assign({}, state,
        { openOrders, rejectedOrders }
      )
    
    case FETCH_ACCOUNT_POSITIONS_SUCCESS:
      return Object.assign({}, state, { positions: action.payload })
    
    case FETCH_MESSAGES_SUCCESS:
      // create msg string for each payload message and
      // add to beginning of existing messages
      messages = state.messages.concat([])
      action.payload.forEach(m => {
        const text = makeMsgString(m)
        if(text) {
          messages.unshift({
            timestamp: m.timestamp,
            text
          })
        }
      })
      
      return Object.assign({}, state,
        { messages }
      )
      
    default:
      return state
  }
}

export default account
