import React from 'react'
import { formatOrderDate } from '../../utils'

const OrderRow = ({order}) => {
  const {
    creationTime,
    instrument,
    avgprice,
    price,
    quantity,
    side,
    type,
    state
  } = order
  return (
    <tr>
      <td>{formatOrderDate(creationTime)}</td>
      <td>{instrument.symbol}</td>
      <td>{avgprice}</td>
      <td>{price}</td>
      <td>{quantity}</td>
      <td>{side} {type}</td>
      <td>{state}</td>
    </tr>
  )
}

export default OrderRow
