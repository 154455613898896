import {
  FETCH_WATCHLIST_SUCCESS,
  FETCH_WATCHLIST_NAMES_SUCCESS,
  INSTRUMENT_QUOTE_RECEIVED
} from '../actions/actionTypes'

import { roundPrice } from '../utils'

const initialState = {
  names: [],
  currId: null,
  instruments: []
}

const watchlist = (state=initialState, action) => {
  let instruments, ret
  
  if(!action) return state
  
  switch(action.type) {
  
    case FETCH_WATCHLIST_NAMES_SUCCESS:
      return Object.assign( {}, state, { names: action.payload } )
  
    case FETCH_WATCHLIST_SUCCESS:
      instruments = action.payload.instruments.map(item => (
        Object.assign({}, item, { bid:0, ask:0 })
      ))
      ret = Object.assign( {}, state,
        {
          currId: action.payload.id,
          instruments
        }
      )
      return ret
      
    case INSTRUMENT_QUOTE_RECEIVED:
      instruments = state.instruments.map(item => {
        if(item.symbol == action.payload.symbol) {
          return Object.assign({}, item,
            { 
              bid: roundPrice(action.payload.bidPrice),
              ask: roundPrice(action.payload.askPrice)
            }
          )
        } else {
          return item
        }
      })
      
      return Object.assign( {}, state, { instruments } )

    default:
      return state
  }
}

export default watchlist
