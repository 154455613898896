export const APP_INIT_FAILURE = 'APP_INIT_FAILURE'
export const APP_INIT_SUCCESS = 'APP_INIT_SUCCESS'

export const APP_ERROR_MODAL_CLOSE_CLICK = 'APP_ERROR_MODAL_CLOSE_CLICK'

export const FETCH_USER_ACCOUNTS_FAILURE = 'FETCH_USER_ACCOUNTS_FAILURE'
export const FETCH_USER_ACCOUNTS_SUCCESS ='FETCH_USER_ACCOUNTS_SUCCESS'
export const FETCH_ACCOUNT_ORDERS_FAILURE = 'FETCH_ACCOUNT_ORDERS_FAILURE'
export const FETCH_ACCOUNT_ORDERS_SUCCESS = 'FETCH_ACCOUNT_ORDERS_SUCCESS'
export const FETCH_ACCOUNT_POSITIONS_FAILURE = 'FETCH_ACCOUNT_POSITIONS_FAILURE'
export const FETCH_ACCOUNT_POSITIONS_SUCCESS = 'FETCH_ACCOUNT_POSITIONS_SUCCESS'
export const SELECT_ACCOUNT = 'SELECT_ACCOUNT'
export const FETCH_MESSAGES_FAILURE = 'FETCH_MESSAGES_FAILURE'
export const FETCH_MESSAGES_SUCCESS = 'FETCH_MESSAGES_SUCCESS'
export const SET_MESSAGE_TYPE_FAILURE = 'SET_MESSAGE_TYPE_FAILURE'

export const ORDER_FORM_MOUNT_FAILURE = 'ORDER_FORM_MOUNT_FAILURE'
export const ORDER_FORM_INSTRUMENT_CHANGE = 'ORDER_FORM_INSTRUMENT_CHANGE'
export const ORDER_FORM_TYPE_CHANGE = 'ORDER_FORM_TYPE_CHANGE'
export const ORDER_FORM_PRICE_CHANGE = 'ORDER_FORM_PRICE_CHANGE'
export const ORDER_FORM_QUANTITY_CHANGE = 'ORDER_FORM_QUANTITY_CHANGE'
export const ORDER_FORM_TIF_CHANGE = 'ORDER_FORM_TIF_CHANGE'
export const ORDER_FORM_DEST_CHANGE = 'ORDER_FORM_DEST_CHANGE'

export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE'

export const FETCH_MARKET_SYMBOLS_SUCCESS = 'FETCH_MARKET_SYMBOLS_SUCCESS'
export const FETCH_ORDER_TIFS_SUCCESS = 'FETCH_ORDER_TIFS_SUCCESS'
export const FETCH_ORDER_TYPES_SUCCESS = 'FETCH_ORDER_TYPES_SUCCESS'
export const FETCH_USER_DESTS_SUCCESS = 'FETCH_USER_DESTS_SUCCESS'

export const FETCH_WATCHLIST_FAILURE = 'FETCH_WATCHLIST_FAILURE'
export const FETCH_WATCHLIST_SUCCESS = 'FETCH_WATCHLIST_SUCCESS'
export const FETCH_WATCHLIST_NAMES_FAILURE = 'FETCH_WATCHLIST_NAMES_FAILURE'
export const FETCH_WATCHLIST_NAMES_SUCCESS = 'FETCH_WATCHLIST_NAMES_SUCCESS'
export const INSTRUMENT_QUOTE_RECEIVED = 'INSTRUMENT_QUOTE_RECEIVED'

export const LOGIN_USERNAME_CHANGE = 'LOGIN_USERNAME_CHANGE'
export const LOGIN_PASSWORD_CHANGE = 'LOGIN_PASSWORD_CHANGE'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'

export const ORDER_FORM_HIDE = 'ORDER_FORM_HIDE'
export const ORDER_FORM_SHOW = 'ORDER_FORM_SHOW'

export const RTR_MOUNT_COMP = 'RTR_MOUNT_COMP'

