/* global expect */

import {
  FETCH_MARKET_SYMBOLS_SUCCESS,
  FETCH_ORDER_TIFS_SUCCESS,
  FETCH_ORDER_TYPES_SUCCESS,
  FETCH_USER_DESTS_SUCCESS,
  ORDER_FORM_HIDE,
  ORDER_FORM_SHOW,
  ORDER_FORM_INSTRUMENT_CHANGE,
  ORDER_FORM_TYPE_CHANGE,
  ORDER_FORM_QUANTITY_CHANGE,
  ORDER_FORM_PRICE_CHANGE,
  ORDER_FORM_TIF_CHANGE,
  ORDER_FORM_DEST_CHANGE
} from '../actions/actionTypes'

const initialState = {
  show: false,
  alertText: '',
  instruments: [],
  instrumentIndex: -1,
  types: [],
  type: '',
  quantity: '',
  price: '',
  tifs: [],
  tif: '',
  destinations: [],
  destinationId: ''
}

const orderForm = (state=initialState, action) => {
  let ret
  
  if(!action) return state
  
  switch(action.type) {
    
    case ORDER_FORM_HIDE:
      return Object.assign({}, state, { show: false })
    
    case ORDER_FORM_SHOW:
      return Object.assign({}, state, { show: true })
    
    case FETCH_USER_DESTS_SUCCESS:
      return Object.assign({}, state,
        {
          destinations: action.payload,
          destinationId: action.payload.length
            ? action.payload[0].id
            : ''
        }
      )
    
    case FETCH_MARKET_SYMBOLS_SUCCESS:
      return Object.assign({}, state,
        {
          instruments: action.payload,
          instrumentIndex: action.payload.length
            ? 0
            : -1
        }
      )
    
    case FETCH_ORDER_TYPES_SUCCESS:
      let types = action.payload.types

      types = types.filter( (type, i) => (
        ( action.payload.permittedIndices.indexOf(i) > -1 )
      ))
      
      return Object.assign({}, state,
        {
          types,
          type: types.length
            ? types[0]
            : ''
        }
      )
    
    case FETCH_ORDER_TIFS_SUCCESS:
      let tifs = action.payload.tifs

      tifs = tifs.filter( (tif, i) => (
        ( action.payload.permittedIndices.indexOf(i) > -1 )
      ))
      
      return Object.assign({}, state,
        {
          tifs,
          tif: tifs.length
            ? tifs[0]
            : ''
        }
      )

    case ORDER_FORM_INSTRUMENT_CHANGE:
      return Object.assign({}, state,
        { instrumentIndex: action.payload }
      )

    case ORDER_FORM_TYPE_CHANGE:
      return Object.assign({}, state,
        { type: action.payload }
      )

    case ORDER_FORM_PRICE_CHANGE:
      return Object.assign({}, state,
        { price: action.payload }
      )

    case ORDER_FORM_QUANTITY_CHANGE:
      return Object.assign({}, state,
        { quantity: action.payload }
      )

    case ORDER_FORM_TIF_CHANGE:
      return Object.assign({}, state,
        { tif: action.payload }
      )

    case ORDER_FORM_DEST_CHANGE:
      return Object.assign({}, state,
        { destinationId: action.payload }
      )

    default:
      return state
  }
  
}

export default orderForm
