import moment from 'moment'

export const formatOrderDate = (mills) => (
  moment(mills).format('YYYY/MM/DD HH:mm:ss')
)

export const roundPrice = (price) => {
  const factor = 100000000
  return Math.round(price * factor) / factor
}
