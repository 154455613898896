import {
  FETCH_WATCHLIST_FAILURE,
  FETCH_WATCHLIST_SUCCESS,
  FETCH_WATCHLIST_NAMES_FAILURE,
  FETCH_WATCHLIST_NAMES_SUCCESS,
  INSTRUMENT_QUOTE_RECEIVED
} from './actionTypes'

import * as backend from '../backend/watchlist'
import * as feed from '../feedService'

const wlNames = [ { id: 1, name: 'default' } ]

const watchlist = [
  { symbol: 'EURUSD', bid: null, ask: null },
  { symbol: 'EURJPY', bid: null, ask: null },
  { symbol: 'EURGBP', bid: null, ask: null },
  { symbol: 'EURCHF', bid: null, ask: null },
  { symbol: 'EURAUD', bid: null, ask: null },
  { symbol: 'EURCAD', bid: null, ask: null },
]

let storeDispatch

export const fetchWatchlistFailure = () => ({
  type: FETCH_WATCHLIST_FAILURE
})

export const fetchWatchlistSuccess = (wl) => ({
  type: FETCH_WATCHLIST_SUCCESS,
  payload: wl
})

export const fetchWatchlistNamesFailure = () => ({
  type: FETCH_WATCHLIST_NAMES_FAILURE
})

export const fetchWatchlistNamesSuccess = (names) => ({
  type: FETCH_WATCHLIST_NAMES_SUCCESS,
  payload: names
})

export const instrumentQuoteReceived = (quote) => ({
  type: INSTRUMENT_QUOTE_RECEIVED,
  payload: quote
})

const handleConnectionOpen = () => {
  watchlist.forEach(item => {
    console.log('subscribe item', item)
    feed.subscribe(new feed.SubscriptionKey(
      item.symbol, 9, "LIQUIDX", "LIQUIDX", "FOREXLEVEL1"
    ))
  })
}

const handleFeedQuote = (quote) => {
  //console.log('handleFeedQuote', quote)
  storeDispatch( instrumentQuoteReceived(quote) )
}

export const panelMount = () => (dispatch) => {
  storeDispatch = dispatch
  dispatch( fetchWatchlistNamesSuccess(wlNames) )
  dispatch( fetchWatchlistSuccess({ instruments: watchlist }) )
  feed.connect(handleConnectionOpen, handleFeedQuote)

  return Promise.resolve()
  /*
  return backend.getWatchlistNames()
    .then(names => {
      dispatch( fetchWatchlistNamesSuccess(names) )
      if(names.length) {
        return backend.getWatchlist(names[0].id)
          .then(wl => {
            dispatch( fetchWatchlistSuccess(wl) )
            dispatch( subscribeInstruments(wl) )
          })
          .catch(err => {
            if(err) console.log('getWatchlist', err)
            dispatch( fetchWatchlistFailure() )
          })
      }
    })
    .catch(err => {
      if(err) console.log('getWatchlistNames', err)
      dispatch( fetchWatchlistNamesFailure() )
    })
  */
}
